<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="联系方式">
                                    <span>{{details.mobile}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="用户昵称">
                                    <span>{{details.customerName}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="反馈内容">
                                    <span>{{details.content}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第3行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="备注">
                                    <span>{{details.remark}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { feedDetail } from '@/api/modular/mallLiving/agreeFeed'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            showBigImg:false,
            id:'',
            details:{},
            imagesList:[],
            bigImg:''
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                feedDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        //如果是未读，调用这个接口
                        // if(record.isRead==1){
                        //     feedRead({id: res.data.id}).then((res)=>{
                        //         if(res.success){
                        //             this.$emit('ok')
                        //         }
                        //     })
                        // }
                        this.confirmLoading = false
                    }
                })
            }, 200)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.details = {}
            this.imagesList = []
        },
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        .con{
            width:30%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
</style>